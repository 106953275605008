import { Box, Skeleton } from "@mui/material";


const SkeletonComponent = ()=>{

    return(
        <Box sx={{ display:'flex', flexDirection:'row', width:'100%' , flexWrap:'wrap' , justifyContent:'center'  }}>
        <Box sx={{ pt: 0.5 , mb:3 }}>
            <Skeleton sx={{m:3,mb:1}} animation="wave" variant="rectangular" width={320} height={300} />
            <Skeleton height={30} sx={{ml:3, mt:1 ,mb:0}}  width="70%" />
            <Skeleton height={30} sx={{ml:3}}  width="30%" />
          </Box>
          <Box sx={{ pt: 0.5 , mb:3 }}>
            <Skeleton sx={{m:3,mb:1}} animation="wave" variant="rectangular" width={320} height={300} />
            <Skeleton height={30} sx={{ml:3, mt:1 ,mb:0}}  width="70%" />
            <Skeleton height={30} sx={{ml:3}}  width="30%" />
          </Box>
          <Box sx={{ pt: 0.5 , mb:3 }}>
            <Skeleton sx={{m:3,mb:1}} animation="wave" variant="rectangular" width={320} height={300} />
            <Skeleton height={30} sx={{ml:3, mt:1 ,mb:0}}  width="70%" />
            <Skeleton height={30} sx={{ml:3}}  width="30%" />
          </Box>
          <Box sx={{ pt: 0.5 , mb:3 }}>
            <Skeleton sx={{m:3,mb:1}} animation="wave" variant="rectangular" width={320} height={300} />
            <Skeleton height={30} sx={{ml:3, mt:1 ,mb:0}}  width="70%" />
            <Skeleton height={30} sx={{ml:3}}  width="30%" />
          </Box>
          <Box sx={{ pt: 0.5 , mb:3 }}>
            <Skeleton sx={{m:3,mb:1}} animation="wave" variant="rectangular" width={320} height={300} />
            <Skeleton height={30} sx={{ml:3, mt:1 ,mb:0}}  width="70%" />
            <Skeleton height={30} sx={{ml:3}}  width="30%" />
          </Box>
          <Box sx={{ pt: 0.5 , mb:3 }}>
            <Skeleton sx={{m:3,mb:1}} animation="wave" variant="rectangular" width={320} height={300} />
            <Skeleton height={30} sx={{ml:3, mt:1 ,mb:0}}  width="70%" />
            <Skeleton height={30} sx={{ml:3}}  width="30%" />
          </Box>
      </Box>
    )
}

export default SkeletonComponent;