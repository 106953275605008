import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {},
});

export const dropdownActions = agentSlice.actions;

export default agentSlice.reducer;
