import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

export default function InfoModal({
  disableClose = false,
  open,
  setOpen,
  title,
  description,
  icon,
  primaryButtonText,
  primaryButtonAction,
  titleStyle = {},
}) {
  return (
    <Modal
      open={open}
      onClose={() => !disableClose && setOpen(false)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: '4rem',
          borderRadius: '16px',
          textAlign: 'center',
          background: '#fff',
          outline: 'none',
          position: 'relative',
          width: '824px',
        }}
      >
        {!disableClose && (
          <CloseIcon
            sx={{
              position: 'absolute',
              right: '2rem',
              top: '2rem',
              cursor: 'pointer',
              color: '#505050',
            }}
            onClick={() => setOpen(false)}
          />
        )}

        {icon && <Box sx={{ textAlign: 'center' }}>{icon}</Box>}

        <Typography
          variant="h6"
          component="h2"
          sx={{
            textAlign: 'center',
            color: '#474747',
            fontSize: '22px',
            fontWeight: '500',
            fontFamily: 'Inter',
            marginBottom: '24px',
            ...titleStyle,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: '16px', color: '#7D7D7D', textAlign: 'center' }}
        >
          {description}
        </Typography>

        {primaryButtonText && primaryButtonAction && (
          <Button
            variant="contained"
            onClick={primaryButtonAction}
            sx={{
              textTransform: 'initial',
              padding: '.4rem 4rem',
              marginTop: '2rem',
            }}
          >
            {primaryButtonText}
          </Button>
        )}
      </Box>
    </Modal>
  );
}
