import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  redirectUrl: '',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loggedIn: (state) => {
      state.isLoggedIn = true;
    },
    loggedOut: (state) => {
      state.isLoggedIn = false;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
