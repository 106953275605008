import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import PropertyList from "../../components/propertyList";
import "./dashboard.css";
import apis from "../../Services/api-services";
import LoaderComponent from "../../components/loader";
import { Skeleton } from "@mui/material";
import SkeletonComponent from "../../components/skeletonComponent";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const [value, setValue] = useState(0);
  const [listedProperties, setListedProperties] = useState([]);
  const [savedProperties, setSavedProperties] = useState([]);
  const [unlockedProperties, setUnlockedProperties] = useState([]);
  const [loading, setLoader] = useState(false);

  useEffect(() => {
    fetchListedProperties();
    fetchSavedProperties();
  }, []);

  useEffect(() => {
    switch (value) {
      case 0:
        fetchListedProperties();
        break;
      case 1:
        fetchUnlockedDetails();
        break;
      case 2:
        fetchSavedProperties();
        break;
      default:
        break;
    }
  }, [value]);

  const fetchListedProperties = () => {
    setLoader(true);
    apis.property
      .getListingProperties()
      .then((data) => {
        setListedProperties(data?.data?.properties);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        console.log("Failed to fetch Listed Properties ", err);
      });
  };

  const fetchUnlockedDetails = () => {
    setLoader(true);
    apis.property
      .getUnlockedProperties()
      .then((data) => {
        setUnlockedProperties(data?.data?.properties);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        console.log("Failed to fetch Saved Properties ", err);
      });
  };

  const fetchSavedProperties = () => {
    setLoader(true);
    apis.property
      .getSavedProperties()
      .then((data) => {
        setSavedProperties(data?.data?.wishlist);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        console.log("Failed to fetch Saved Properties ", err);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: "20px 30px" }}>
      <Box sx={{ paddingLeft: "20px" }} className="dashboard-tabs-container">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          className="dashboard-tabs"
        >
          <Tab
            label="Your Properties"
            className="dashboard-tab"
            {...a11yProps(0)}
          />
          <Tab
            label="Unlocked Properties"
            className="dashboard-tab"
            {...a11yProps(1)}
          />
          <Tab
            label="Saved Properties"
            className="dashboard-tab"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading ? (
          <SkeletonComponent />
        ) : (
          <PropertyList
            from="listing"
            data={listedProperties}
            hideFilter={true}
            showDays={true}
            parentClasses="width1-3"
            fetchListedProperties={fetchListedProperties}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {loading ? (
          <SkeletonComponent />
        ) : (
          <PropertyList
            from="unlock"
            data={unlockedProperties}
            // fromSaved={true}
            hideFilter={true}
            parentClasses="width1-3"
            fetchListedProperties={()=>{}}

          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {loading ? (
          <SkeletonComponent />
        ) : (
          <PropertyList
            from="saved"
            data={savedProperties}
            fromSaved={true}
            hideFilter={true}
            parentClasses="width1-3"
            fetchListedProperties={()=>{}}

          />
        )}
      </CustomTabPanel>
    </Box>
  );
}
