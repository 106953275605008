import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { ADD_PROPERTY, DASHBOARD, HOME } from '../constants/route-path';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import GridViewIcon from '@mui/icons-material/GridView';
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from 'react-router-dom';


export default function HomeSidebar({open,setOpen}) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const navlinks = [
    {
      title: "Home",
      id: 1,
      to: HOME,
      icon:<HomeRoundedIcon />
    },
    {
      title: "Dashboard",
      id: 2,
      to: DASHBOARD,
      icon:<GridViewIcon />
    },
    // {
    //     title:'Messages',
    //     id:3,
    //     to:MESSAGES
    // }
  ];

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
         {pathname !== ADD_PROPERTY && (
          <span
            className="nav-list-property-btn"
            style={{marginTop:'40px'}}
            onClick={() => navigate("/add-property")}
          >
            <AddIcon className="nav-add-icon" />
            List my Property
          </span>
        )}
      <List>
        {navlinks.map((item, index) => (
          <ListItem key={item.id} disablePadding onClick={()=>navigate(item.to)}>
            <ListItemButton>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}