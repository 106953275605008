import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: {},
  userName: '',
  isExistingUser: false,
  selectedChatID: '',
  feedback: {},
  isUserVerified: '',
  userUnlocksCount: '',
  userAvatar: '',
  userId: '',
  userEmail: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setIsExistingUser: (state, action) => {
      state.isExistingUser = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setSelectedChatID: (state, action) => {
      state.selectedChatID = action.payload;
    },
    resetSelectedID: (state) => {
      state.selectedChatID = false;
    },
    setFeedBack: (state, action) => {
      state.feedback = {
        ...state.feedback,
        [action.payload.messageId]: action.payload.feedbackType,
      };
    },
    setUserAvatar(state, action) {
      state.userAvatar = action.payload;
    },
    setIsUserVerified(state, action) {
      state.isUserVerified = action.payload;
    },
    setUserUnlocksCount(state, action) {
      state.userUnlocksCount = action.payload;
    },
    clearUserData: (state) => {
      state.isExistingUser = false;
      state.userDetails = {};
      state.userName = '';
      state.selectedChatID = false;
      state.feedback = {};
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
