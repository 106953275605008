import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { loginActions } from '../Store/loginSlice';

export default function Protected({ children }) {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const location = useLocation();
  const dispatch = useDispatch();

  if (!isLoggedIn) {
    dispatch(loginActions.setRedirectUrl(location.pathname + location.search));
    return <Navigate to="/login" replace />;
  }

  return children;
}
