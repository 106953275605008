import React from 'react';
import { Box, Skeleton } from '@mui/material';

const Placeholder = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          columnGap: 2,
          border: '1px solid #D2D2D2',
          padding: '.7rem',
          borderRadius: '8px',
        }}
      >
        <Skeleton
          variant="rectangular"
          width={150}
          height={120}
          animation={false}
          sx={{ borderRadius: '8px' }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.2 }}>
          <Skeleton
            variant="rectangular"
            width={210}
            height={30}
            animation={false}
            sx={{ borderRadius: '4px' }}
          />
          <Skeleton
            variant="rectangular"
            width={210}
            height={40}
            animation={false}
            sx={{ borderRadius: '4px' }}
          />
          <Skeleton
            variant="rectangular"
            width={210}
            height={30}
            animation={false}
            sx={{ borderRadius: '4px' }}
          />
        </Box>

        <Skeleton
          variant="rectangular"
          width={60}
          height={30}
          animation={false}
          sx={{ borderRadius: '8px' }}
        />
      </Box>
    </>
  );
};

export default Placeholder;
