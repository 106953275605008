import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import userReducer from './userSlice';
import dropdownReducer from './dropdownSlice';
import propertyReducer from './propertySlice';
import agentReducer from './agentSlice';

const reducers = combineReducers({
  login: loginReducer,
  userDetails: userReducer,
  dropdown: dropdownReducer,
  property: propertyReducer,
  agent: agentReducer,
});

export default reducers;
