import React, { useEffect, useRef, useState } from 'react';
import './messages.css';
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Skeleton,
} from '@mui/material';
import SEARCHICON from '../../Assets/images/search-icon.png';
import OPTIONICON from '../../Assets/images/options-icon.png';
import PROFILEBLUEICON from '../../Assets/images/profile-blue-icon.svg';
import APARTMENTICON from '../../Assets/images/Building-icon.svg';
import NOCHAT from '../../Assets/images/no-chat.svg';
import ADDICON from '../../Assets/images/Add-icon.png';
import SENDICON from '../../Assets/images/Send-icon.png';
import { LOCAL_STORAGE_DATA, SOCKETURL } from '../../constants/app-constants';
import { getAccessToken } from '../../Utils/helperUtils';
import apis from '../../Services/api-services';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import ProperyDetail from '../../components/propertyDetail';
import ProfileDetail from '../../components/profileDetails';

const Messages = () => {
  const navigate = useNavigate();

  const [searchUser, setSearchUser] = useState('');
  const [isUnlockedChats, setIsUnlockedChat] = useState(true);
  const socket = useRef(null);
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [alertType, setAlertType] = useState('');
  const [alertMsg, setAlertmsg] = useState(null); // State to store error messages
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const messageWindowRef = useRef(null);
  const [userInput, setUserInput] = useState('');
  const [unlockedChatsList, setUnlockedChatsList] = useState([]);
  const [leadChatsList, setLeadChatsList] = useState([]);
  const [currentChatID, setcurrentChatID] = useState('');
  const [currentChat, setcurrentChat] = useState('');
  const [chatListLoader, setChatListLoader] = useState(true);
  const [chatLoader, setChatLoader] = useState(false);
  const [openPropertyDetail, setOpenPropertyDetail] = useState(false);
  const [openProfileDetail, setOpenProfileDetail] = useState(false);
  const [propertyID, setPropertyID] = useState(location.state);

  useEffect(() => {
    isUnlockedChats ? getUnlockList() : getLeadsList();
    if (propertyID?.chat_room) {
      setcurrentChatID(propertyID.chat_room);
      loadChatHistory(propertyID.chat_room);
    }

    if (isUnlockedChats && propertyID?.property_id) {
      apis.unlockchats
        .getUnlockList()
        .then((response) => {
          setChatListLoader(false);
          if (response.status === 200) {
            const matched = response?.data?.chats?.find((item) => {
              return item.property_id === propertyID.property_id;
            });
            if (matched?.chat_room) {
              setcurrentChatID(matched.chat_room);
            }
          }
        })
        .catch((err) => {
          setChatListLoader(false);
          console.log('Error fetching Unlock list', err);
        });
    }
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!propertyID && !currentChatID) {
      return;
    }

    socket.current = new WebSocket(
      currentChatID
        ? `${SOCKETURL}${accessToken}&room_group_name=${currentChatID}`
        : `${SOCKETURL}${accessToken}&property_id=${propertyID?.property_id}`
    );

    socket.current.onopen = () => {
      console.log('WebSocket is open now.');
    };

    // socket.current.onmessage = (event) => {
    //   const data = JSON.parse(event.data);
    //   setLoading(false);
    //   clearTimeout(loadingTimeoutRef.current);
    //   setMessages((prevMessages) => [...prevMessages, { text: data.message, sender: 'AIRA' }]);
    //   onNewMessage(data.message, data.chat_title);
    //   scrollToBottom();
    // };
    socket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.error === 'Invalid Token') {
        // handleInvalidToken(); // Trigger token refresh if token is invalid
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: data.message,
            is_sender: false,
            time: data.time,
          },
        ]);
        // onNewMessage(data.message);
      }
    };

    socket.current.onclose = () => {
      console.log('WebSocket is closed now.');
    };

    socket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setAlertmsg('Something went Wrong!! Chat Closed.');
      !loading && setOpenModal(true);
      // setAlertType("error");
      // setOpenSnackbar(true);
      // handleInvalidToken(); // Trigger token refresh if token is invalid
    };

    return () => {
      if (socket.current && socket.current.readyState === WebSocket.OPEN) {
        socket.current.close();
      }
    };
  }, [currentChatID]);

  useEffect(() => {
    if (currentChatID) {
      loadChatHistory(currentChatID);
    }
  }, [currentChatID]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messageWindowRef.current) {
        messageWindowRef.current.scrollTop =
          messageWindowRef.current.scrollHeight;
      }
    }, 0);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenPropertyDetail(newOpen);
  };

  const toggleProfileDrawer = (newOpen) => () => {
    setOpenProfileDetail(newOpen);
  };

  const loadChatHistory = (id) => {
    setChatLoader(true);
    apis.unlockchats
      .loadChats(id)
      .then((response) => {
        setChatLoader(false);
        if (response.status === 200) {
          let [chatHistory] = response?.data?.chats;
          setcurrentChat(chatHistory);
          setMessages(chatHistory?.messages);
        } else {
          setMessages([]);
          setcurrentChat('');
        }
      })
      .catch((err) => {
        setChatLoader(false);
        console.log('Error fetching Unlock list', err);
      });
  };

  const handleUserInput = (message) => {
    
    const token = localStorage.getItem(LOCAL_STORAGE_DATA.ACCESS_TOKEN); // Get access token
    if(userInput !==''){
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: userInput,
          is_sender: true,
          time: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        },
      ]);
  
      socket.current.send(JSON.stringify({ message: userInput })); // Send message with token
      setUserInput('');
    }
  };

  const getUnlockList = () => {
    setChatListLoader(true);
    apis.unlockchats
      .getUnlockList()
      .then((response) => {
        setChatListLoader(false);
        if (response.status === 200) {
          setUnlockedChatsList(response?.data?.chats);
          let [firstChat] = response?.data?.chats;
        } else {
          setUnlockedChatsList([]);
        }
      })
      .catch((err) => {
        setChatListLoader(false);
        console.log('Error fetching Unlock list', err);
      });
  };

  const getLeadsList = () => {
    setChatListLoader(true);
    apis.unlockchats
      .getLeads()
      .then((response) => {
        setChatListLoader(false);
        if (response.status === 200) {
          let list = response?.data?.chats;
          setLeadChatsList(list);
          let [firstChat] = list;
        } else {
          setLeadChatsList([]);
        }
      })
      .catch((err) => {
        setChatListLoader(false);
        console.log('Error fetching Unlock list', err);
      });
  };

  const handleChatListClick = (isUnlock) => {
    setPropertyID(null);
    if (isUnlock) {
      setIsUnlockedChat(true);
      getUnlockList();
    } else {
      setIsUnlockedChat(false);
      getLeadsList();
    }
    setcurrentChatID('');
    setcurrentChat('');
    setMessages([]);
  };

  const handlePropertyClick = () => {
    if (currentChat?.property_id || propertyID?.property_id) {
      setOpenPropertyDetail(true);
    }
  };

  const handleProfileClick = () => {
    if (currentChat?.broker_id || propertyID?.broker_id) {
      setOpenProfileDetail(true);
      // navigate(
      //   `/profile?customer_id=${
      //     currentChat?.broker_id || propertyID?.broker_id
      //   }`
      // );
    }
  };

  return (
    <div className="messages-container">
      <div className="messages-chat-history">
        <Box className="home-search-input" sx={{ width: '100%' }}>
          <FormControl
            size="small"
            sx={{
              m: 1,
              width: '95%',
              marginTop: 2,
              borderRadius: '12px',
              // background: "rgba(244, 244, 244, 1)",
              border: 'none',
              '& fieldset': {
                // border: "none",
                borderRadius: '12px',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '14px',
              },
            }}
          >
            <OutlinedInput
              placeholder="Search messages"
              value={searchUser}
              onChange={(e) => setSearchUser(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <img src={SEARCHICON} alt="search" width={35} height={35} />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <span className="messages-chat-msg-title">Messages</span>
        <div className="messages-chat-btns">
          <span
            className={`messages-chat-btn ${
              !isUnlockedChats ? 'active-msg-chat-btn' : ''
            }`}
            onClick={() => handleChatListClick(false)}
          >
            My Leads
          </span>
          <span
            className={`messages-chat-btn ${
              isUnlockedChats ? 'active-msg-chat-btn' : ''
            }`}
            onClick={() => handleChatListClick(true)}
          >
            Unlocked
          </span>
        </div>
        <span className="messages-messages-chat-history-text">
          {isUnlockedChats
            ? `Properties you're interested in are shown here.`
            : `Leads interested in your property are shown here.`}
        </span>
        {isUnlockedChats ? (
          <div className="messages-chat-list">
            {chatListLoader ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '330px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
              </Box>
            ) : (
              unlockedChatsList?.length > 0 &&
              unlockedChatsList.map((item, index) => (
                <div
                  key={index}
                  className={`messages-chat-list-item ${
                    currentChatID === item.chat_room
                      ? 'active-chat-list-item'
                      : ''
                  }`}
                  onClick={() => setcurrentChatID(item.chat_room)}
                >
                  <div className="messages-chat-list-property-image">
                    <img
                      className="messages-chat-list-property-icon"
                      src={item.cover_picture}
                      alt="property"
                    />
                  </div>
                  <div className="messages-chat-list-info">
                    <span className="messages-chat-list-info-name">
                      {item.property_title}
                    </span>
                    <span className="messages-chat-list-info-msg">
                      {item.last_message}
                    </span>
                    <span className="messages-chat-list-info-contact">
                      <img
                        className="messages-chat-list-pro-icon"
                        src={PROFILEBLUEICON}
                        alt="profile"
                      />
                      <span className="messages-chat-list-overflow-text">
                        {item.owner_name}
                      </span>
                    </span>
                  </div>
                  <div className="messages-chat-list-stat">
                    {item.last_message_time && (
                      <span className="messages-chat-list-time">
                        {dayjs(item.last_message_time).format('h:mm A')}
                      </span>
                    )}
                    {/* <span className="messages-chat-list-count">
                      {item.unread_messages}
                    </span> */}
                  </div>
                </div>
              ))
            )}
          </div>
        ) : (
          <div className="messages-chat-list">
            {chatListLoader ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '330px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={60}
                  sx={{ marginX: 2, marginY: 1 }}
                />
              </Box>
            ) : (
              leadChatsList?.length > 0 &&
              leadChatsList.map((item, index) => (
                <div
                  key={index}
                  className={`messages-chat-list-item ${
                    currentChatID === item.chat_room
                      ? 'active-chat-list-item'
                      : ''
                  }`}
                  onClick={() => setcurrentChatID(item.chat_room)}
                >
                  <div className="messages-chat-list-property-image">
                    <img
                      className="messages-chat-list-property-icon"
                      src={item.profile_picture}
                      alt="property"
                    />
                  </div>
                  <div className="messages-chat-list-info">
                    <span className="messages-chat-list-info-name">
                      {item.lead_name}
                    </span>
                    <span className="messages-chat-list-info-msg">
                      {item.last_message}
                    </span>
                    <span className="messages-chat-list-info-contact">
                      <img
                        className="messages-chat-list-pro-icon"
                        src={APARTMENTICON}
                        alt="profile"
                      />
                      <span className="messages-chat-list-overflow-text">
                        {item.property_title}
                      </span>
                    </span>
                  </div>
                  <div className="messages-chat-list-stat">
                    {item.last_message_time && (
                      <span className="messages-chat-list-time">
                        {dayjs(item.last_message_time).format('h:mm A')}
                      </span>
                    )}
                    {/* <span className="messages-chat-list-count">
                      {item.unread_messages}
                    </span> */}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <div className="messages-current-chat-window">
        {!currentChatID && !propertyID ? (
          <div className="messages-current-no-chat-window">
            <img src={NOCHAT} className="no-chat-img" alt="no-chat" />
            <span className="no-chat-title">
              It’s nice to chat with someone
            </span>
            <span className="no-chat-subtitle">
              Pick a person from left menu and start your conversation
            </span>
          </div>
        ) : (
          <>
            <div className="messages-current-chat-header">
              <span className="messages-current-chat-header-profile">
                {chatLoader ? (
                  <Skeleton
                    variant="circular"
                    sx={{ marginRight: 2 }}
                    width={40}
                    height={40}
                  />
                ) : isUnlockedChats ? (
                  (currentChat?.cover_picture || propertyID?.cover_picture) && (
                    <img
                      className="messages-current-profile-pic"
                      src={
                        currentChatID
                          ? currentChat?.cover_picture
                          : propertyID?.cover_picture
                      }
                      alt="user"
                    />
                  )
                ) : (
                  <img
                    className="messages-current-profile-pic"
                    src={
                      currentChatID
                        ? currentChat?.profile_picture
                        : propertyID?.cover_picture
                    }
                    alt="user"
                  />
                )}
                {chatLoader ? (
                  <Skeleton variant="rounded" width={160} height={50} />
                ) : (
                  <span
                    className="messages-current-profile-name"
                    onClick={() =>
                      isUnlockedChats
                        ? handlePropertyClick()
                        : handleProfileClick()
                    }
                  >
                    {isUnlockedChats
                      ? currentChatID
                        ? currentChat?.property_title
                        : propertyID?.property_title
                      : currentChat?.full_name}
                  </span>
                )}
              </span>
              {chatLoader ? (
                <Skeleton variant="rounded" width={100} height={40} />
              ) : isUnlockedChats ? (
                (currentChat?.property_owner_name ||
                  propertyID?.owner_name) && (
                  <span
                    className="messages-chat-list-info-contact"
                    onClick={() => handleProfileClick()}
                  >
                    <img
                      className="messages-chat-list-pro-icon"
                      src={PROFILEBLUEICON}
                      alt="profile"
                    />
                    <span className="messages-chat-list-overflow-text">
                      {currentChatID
                        ? currentChat?.property_owner_name
                        : propertyID?.owner_name}
                    </span>
                  </span>
                )
              ) : (
                <span
                  className="messages-chat-list-info-contact"
                  onClick={() => handlePropertyClick()}
                >
                  <img
                    className="messages-chat-list-pro-icon"
                    src={APARTMENTICON}
                    alt="profile"
                  />
                  <span className="messages-chat-list-overflow-text">
                    {currentChatID
                      ? currentChat?.property_title
                      : propertyID?.property_title}
                  </span>
                </span>
              )}
              {/* <span className="messages-header-options">
                <img
                  src={OPTIONICON}
                  className="messages-options-icon"
                  alt="options"
                />
              </span> */}
            </div>
            <div
              className="messages-current-chat-section"
              ref={messageWindowRef}
            >
              {chatLoader ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    width={300}
                    height={30}
                    sx={{ marginX: 2, marginY: 1 }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={300}
                    height={30}
                    sx={{ marginX: 2, marginY: 1, alignSelf: 'flex-end' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={300}
                    height={30}
                    sx={{ marginX: 2, marginY: 1 }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={300}
                    height={30}
                    sx={{ marginX: 2, marginY: 1, alignSelf: 'flex-end' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={300}
                    height={30}
                    sx={{ marginX: 2, marginY: 1 }}
                  />
                </Box>
              ) : (
                messages?.length > 0 &&
                messages.map((item) => (
                  <div
                    className={`message-item-container ${
                      item.is_sender ? 'message-item-sender' : ''
                    }`}
                  >
                    <div
                      className={`individual-message ${
                        item.is_sender ? 'individual-item-sender' : ''
                      }`}
                    >
                      {item.message}
                      <span className="individual-message-time">
                        {dayjs(item.time).format('h:mm a')}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="messages-current-chat-input">
              <Box className="home-search-input" sx={{ width: '100%' }}>
                <FormControl
                  size="small"
                  sx={{
                    m: 1,
                    width: '98%',
                    minWidth: 300,
                    marginTop: 2,
                    color: '#7D7D7D',
                    borderRadius: '16px',
                    background: 'rgba(244, 244, 244, 1)',
                    border: 'none',
                    '& fieldset': {
                      // border: "none",
                      borderRadius: '16px',
                    },
                    '& .MuiOutlinedInput-input': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <OutlinedInput
                    placeholder="Write a message....."
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    multiline
                    disabled={chatListLoader || chatLoader}
                    maxRows={5}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey && !chatLoader) {
                        e.preventDefault();
                        handleUserInput();
                      }
                    }}
                  />
                </FormControl>
              </Box>
              <div className="messages-current-chat-input-btn messages-current-chat-input-add">
                <img src={ADDICON} className="messages-input-icon" alt="add" />
              </div>
              <div
                className="messages-current-chat-input-btn messages-current-chat-input-send"
                onClick={handleUserInput}
              >
                <img
                  src={SENDICON}
                  className="messages-input-icon"
                  alt="send"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <ProperyDetail
        selectedPropertyID={currentChat?.property_id || propertyID?.property_id}
        toggleDrawer={toggleDrawer}
        open={openPropertyDetail}
        setOpen={setOpenPropertyDetail}
        from={isUnlockedChats ? 'unlock' : 'home'}
        fromMsg={true}
      />
      <ProfileDetail
        customer_id={currentChat?.broker_id || propertyID?.broker_id}
        toggleDrawer={toggleProfileDrawer}
        open={openProfileDetail}
        setOpen={setOpenProfileDetail}
      />
    </div>
  );
};

export default Messages;
