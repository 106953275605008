const ImagesLinks = {
  vrefydLogoWhite: require('../Assets/images/vREfyd_logo.png'),
  backgroundFrame: require('../Assets/images/autofill-with-aira-frame.png'),
  priorityVisibility: require('../Assets/images/PriorityVisibility.png'),
  purpleTickBadge: require('../Assets/images/purple-tick-badge.png'),
  exclusiveFilters: require('../Assets/images/Exclusive-Filters.png'),
  businessDetailsIcon: require('../Assets/images/business-details-icon.png'),
  kycDocIcon: require('../Assets/images/kyc-doc-icon.png'),
  realEstateProofIcon: require('../Assets/images/real-estate-proof-icon.png'),
  addressBookIcon: require('../Assets/images/address-book-icon.png'),
  indianFlag: require('../Assets/images/indian-flag.png'),
  securedByDesign: require('../Assets/images/secured-by-design-logo.png'),
  imageVerification: require('../Assets/images/image-verification.png'),
  cameraIcon: require('../Assets/images/camera-icon.png'),
  uploadPhotoIcon: require('../Assets/images/upload-photo-icon.png'),
  encryptedIcon: require('../Assets/images/encrypted-icon.png'),
  vrefydQrLogo: require('../Assets/images/vrefyd-qr-logo.png'),
};

export default ImagesLinks;
