import requestServer from "../request-server";

let internals = {};

internals.getUnlockList = function (data) {
  return requestServer(
    "v1/user/chats/unlocked",
    data,
    "GET",
    {},
    "application/json",
    true
  );
};

internals.getLeads = function (data) {
  return requestServer(
    `v1/user/chats/lead`,
    data,
    "GET",
    {},
    "application/json",
    true
  );
};

internals.loadChats = function (id) {
  return requestServer(
    `v1/user/chat/${id}/messages`,
    '',
    "GET",
    {},
    "application/json",
    true
  );
};




export default internals;
