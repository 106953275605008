import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./advance-filters.css";
import { useEffect, useState } from "react";
import {
  maxRentValue,
  maxSaleValue,
  minRentValue,
  minSaleValue,
  RENTVALUE,
  SALEVALUE,
} from "../constants/app-constants";
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Slider,
  TextField,
} from "@mui/material";
import { numDifferentiation, sanitizeData } from "../Utils/helperUtils";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

const RenderActiveFilter = (props) => {
  const {
    filterKey,
    setbudgetRange,
    budgetRange,
    propertyType,
    maxBuildUp,
    minBuildUp,
    setMaxBuildUp,
    setMinBuildUp,
    availabilityDate,
    setAvailabilityDate,
  } = props;

  const getMinSliderValue = () => {
    if (propertyType.includes(SALEVALUE) && propertyType.includes(RENTVALUE)) {
      return minRentValue;
    } else if (propertyType.includes(SALEVALUE)) {
      return minSaleValue;
    } else {
      return minRentValue;
    }
  };

  const getMaxSliderValue = () => {
    if (propertyType.includes(SALEVALUE) && propertyType.includes(RENTVALUE)) {
      return maxSaleValue;
    } else if (propertyType.includes(RENTVALUE)) {
      return maxRentValue;
    } else {
      return maxSaleValue;
    }
  };

  const handleChange = (event, newValue) => {
    setbudgetRange(newValue);
  };
  function valuetext(value) {
    return `${value}`;
  }

  const handleBuildupChange = (val, isMin) => {
    if (isMin) {
      val >= 0 && setMinBuildUp(val);
    } else {
      setMaxBuildUp(val);
    }
  };

  if (filterKey === "build_up_area") {
    return (
      <div className="adv-fil-build-up-area">
        <FormControl sx={{ m: 1 }} variant="outlined">
          <span className="adv-fil-active-label">Min Build Up</span>
          <OutlinedInput
            type="number"
            value={minBuildUp}
            onChange={(e) => handleBuildupChange(e.target.value, true)}
            endAdornment={
              <InputAdornment
                sx={{
                  ".MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
                position="end"
              >
                Sq. ft.
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            size="small"
            autoComplete="off"
            inputProps={{
              "aria-label": "Sq. Ft",
            }}
            sx={{
              width: 200,
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              ".MuiOutlinedInput-input": {
                fontSize: "14px",
              },
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }} variant="outlined">
          <span className="adv-fil-active-label">max Build Up</span>

          <OutlinedInput
            type="number"
            value={maxBuildUp}
            onChange={(e) => handleBuildupChange(e.target.value, false)}
            endAdornment={
              <InputAdornment
                sx={{
                  ".MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
                position="end"
              >
                Sq. ft.
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            size="small"
            autoComplete="off"
            inputProps={{
              "aria-label": "Sq. Ft",
            }}
            sx={{
              width: 200,
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              ".MuiOutlinedInput-input": {
                fontSize: "14px",
              },
            }}
          />
        </FormControl>
      </div>
    );
  } else if (filterKey === "budget") {
    return (
      <Box sx={{ width: "95%", padding: 3 }}>
        <Slider
          getAriaLabel={() => "Price Range"}
          value={budgetRange}
          onChange={handleChange}
          valueLabelDisplay="on"
          getAriaValueText={valuetext}
          min={getMinSliderValue()}
          max={getMaxSliderValue()}
          valueLabelFormat={(value) => numDifferentiation(value)}
          sx={{
            width: "100%",
            ".MuiSlider-thumb": {
              width: "10px",
              height: "10px",
            },
            ".MuiSlider-valueLabel": {
              background: "#F5EDFF",
              color: "#7D7D7D",
              borderRadius: "30px",
              top: 50,
              "::before": {
                // Adjust the arrow pointing direction
                content: '""',
                position: "absolute",
                width: 0,
                height: 0,
                borderLeft: "7px solid #fff",
                borderRight: "7px solid #fff",
                borderTop: "7px solid #F5EDFF", // Arrow color
                top: -6, // This adjusts the arrow's position to point upwards
                left: "50%",
                transform: "translateX(-50%) rotate(180deg)",
              },
            },
          }}
        />
      </Box>
    );
  } else if (filterKey === "Availability") {
    return (
      <div className="adv-fil-Availability">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={availabilityDate}
            onChange={(newValue) => setAvailabilityDate(newValue)}
          />
        </LocalizationProvider>
      </div>
    );
  } else {
    return <></>;
  }
};

export default function AdvanceFiltersModal(props) {
  const {
    open,
    setOpen,
    dropDownValues,

    propertyType,
    setPropertyType,
    houseType,
    setHouseType,
    furnishType,
    setFurnishType,
    bedrooms,
    setBedRooms,
    location,
    setLocation,
    budgetRange,
    setbudgetRange,
    setPropertyList,
    selectedCity,
    setSelectedCity,
    handleSubmit,
    minBuildUp,
    maxBuildUp,
    availabilityDate,
    setAvailabilityDate,
    from
  } = props;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedFilter, setSelectedFilter] = useState("furnish_types");

  const getDropDownTitle = (key) => {
    switch (key) {
      case "bedrooms":
        return "Bedrooms";
      case "cities":
        return "cities";
      case "furnish_types":
        return "Furnishing";
      case "new_list_type":
        return "";
      case "property_types":
        return "Property Type";
      default:
        return key;
    }
  };

  const handleDropSelection = (value, state, setState) => {
    if (state?.includes(value)) {
      let temparr = state.filter((item) => {
        return item !== value;
      });
      setState(temparr);
    } else {
      setState([...state, value]);
    }
  };

  const getStateValues = (type) => {
    switch (type) {
      case "bedrooms":
        return { state: bedrooms, setState: setBedRooms };
      case "cities":
        return { state: selectedCity, setState: setSelectedCity };
      case "furnish_types":
        return { state: furnishType, setState: setFurnishType };
      case "new_list_type":
        return { state: propertyType, setState: setPropertyType };
      case "property_types":
        return { state: houseType, setState: setHouseType };
      default:
        return;
    }
  };

  const handleFilterclicked = (id, value) => {
    switch (id) {
      case "bedrooms":
        setBedRooms(value);
        break;
      case "cities":
        setSelectedCity(value);
        break;
      case "furnish_types":
        setFurnishType(value);
        break;
      case "new_list_type":
        setPropertyType(value);
        break;
      case "property_types":
        setHouseType(value);
        break;
      default:
        return;
    }
  };

  const getAvailityCount = () => {
    if (propertyType?.includes(RENTVALUE)) {
      return getStateCount(availabilityDate);
    } else return 0;
  };

  const getBuildUpCount = () => {
    if (minBuildUp && maxBuildUp) {
      return 1;
    } else {
      return 0;
    }
  };

  function getStateCount(...arrs) {
    return arrs.filter((item) => {
      if (Array.isArray(item)) {
        return item.length > 0; // Non-empty array
      } else if (typeof item === "string") {
        return item.trim().length > 0; // Non-empty string (ignoring whitespace)
      } else if (typeof item === "number") {
        return !isNaN(item); // Valid number
      } else if (typeof item === "object" && item !== null) {
        if (item instanceof Date) {
          return !isNaN(item.getTime()); // Valid date
        }
        return Object.keys(item).length > 0; // Non-empty object
      } else {
        return false; // Ignore other types (e.g., null, undefined, booleans)
      }
    }).length;
  }

  const handleClear = () => {
    setLocation("");
    setHouseType([]);
    setBedRooms([]);
    setFurnishType([]);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="advance-fil-container">
          <div className="advance-fil-content">
            <div className="adv-fil-header">
              <span>Filters</span>
              <span>
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </span>
            </div>
            <div className="advance-fil-devider"></div>
            <div className="advance-fil-header-btns">
              <span
                className={`advance-fil-btn ${
                  propertyType?.includes(RENTVALUE) ? "active-fil-btn" : ""
                }`}
                onClick={() =>
                  handleDropSelection(RENTVALUE, propertyType, setPropertyType)
                }
              >
                {RENTVALUE}
              </span>
              <span
                className={`advance-fil-btn ${
                  propertyType?.includes(SALEVALUE) ? "active-fil-btn" : ""
                } `}
                onClick={() =>
                  handleDropSelection(SALEVALUE, propertyType, setPropertyType)
                }
              >
                {SALEVALUE}
              </span>
            </div>
            <div className="advance-fil-filters">
              <div className="advance-fil-disp-list advance-fil-list">
                <span
                  onClick={() => setSelectedFilter("cities")}
                  className={`advance-fil-item ${
                    selectedFilter === "cities" ? "active-advance-fil-item" : ""
                  }`}
                >
                  {" "}
                  {sanitizeData(selectedCity) && (
                    <span className="is-selected-fil"></span>
                  )}{" "}
                  City
                </span>
                <span
                  onClick={() => setSelectedFilter("property_types")}
                  className={`advance-fil-item ${
                    selectedFilter === "property_types"
                      ? "active-advance-fil-item"
                      : ""
                  }`}
                >
                  {" "}
                  {sanitizeData(houseType) && (
                    <span className="is-selected-fil"></span>
                  )}
                  Property Type
                </span>
                <span
                  onClick={() => setSelectedFilter("bedrooms")}
                  className={`advance-fil-item ${
                    selectedFilter === "bedrooms"
                      ? "active-advance-fil-item"
                      : ""
                  }`}
                >
                  {" "}
                  {sanitizeData(bedrooms) && (
                    <span className="is-selected-fil"></span>
                  )}
                  Bedrooms
                </span>
                <span
                  onClick={() => setSelectedFilter("furnish_types")}
                  className={`advance-fil-item ${
                    selectedFilter === "furnish_types"
                      ? "active-advance-fil-item"
                      : ""
                  }`}
                >
                  {" "}
                  {sanitizeData(furnishType) && (
                    <span className="is-selected-fil"></span>
                  )}
                  Furnishing
                </span>
                <span
                  onClick={() => setSelectedFilter("build_up_area")}
                  className={`advance-fil-item ${
                    selectedFilter === "build_up_area"
                      ? "active-advance-fil-item"
                      : ""
                  }`}
                >
                  {" "}
                  {sanitizeData(minBuildUp) && sanitizeData(maxBuildUp) && (
                    <span className="is-selected-fil"></span>
                  )}
                  Build Up Area
                </span>
                <span
                  onClick={() => setSelectedFilter("budget")}
                  className={`advance-fil-item ${
                    selectedFilter === "budget" ? "active-advance-fil-item" : ""
                  }`}
                >
                  {" "}
                  {sanitizeData(budgetRange) && (
                    <span className="is-selected-fil"></span>
                  )}
                  Budget
                </span>
                {propertyType?.includes(RENTVALUE) && (
                  <span
                    onClick={() => setSelectedFilter("Availability")}
                    className={`advance-fil-item ${
                      selectedFilter === "Availability"
                        ? "active-advance-fil-item"
                        : ""
                    }`}
                  >
                    {" "}
                    {sanitizeData(availabilityDate) && (
                      <span className="is-selected-fil"></span>
                    )}
                    Availability
                  </span>
                )}
              </div>
              <div className="advance-fil-disp-list active-advance-fil-list">
                {dropDownValues[selectedFilter]?.map((item) => (
                  <span
                    onClick={() =>
                      handleDropSelection(
                        item.value,
                        getStateValues(selectedFilter).state,
                        getStateValues(selectedFilter).setState
                      )
                    }
                    className={`advance-fil-item ${
                      [...getStateValues(selectedFilter).state].includes(
                        item.value
                      )
                        ? "active-advance-fil-item"
                        : ""
                    }`}
                    id={item.value}
                  >
                    {item.value}
                  </span>
                ))}
                <RenderActiveFilter filterKey={selectedFilter} {...props} />
              </div>
            </div>
            <div className="advance-fil-details">
              {getStateCount(selectedCity, houseType, bedrooms, furnishType) +
                getAvailityCount() +
                getBuildUpCount() +
                1}{" "}
              filter(s) added
            </div>
          </div>

          <div className="advance-fil-footer">
            <Button
              onClick={handleClear}
              type="submit"
              size="small"
              sx={{
                width: 100,
                background: "#fffff",
                border: "1px solid ",
                fontSize: "12px",
                textTransform: "none",
                m: 1,
              }}
            >
              Clear All
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                fontSize: "12px",
                width: 100,
                m: 1,
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
