import {
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';

export default function DropdownWithLabel({
  hideLabel = false,
  placeholder = '',
  label = '',
  required = false,
  value = '',
  values = [],
  touched,
  errors,
  name = '',
  helperText = '',
  lg = '',
  ...props
}) {
  return (
    <Grid item lg={lg || 5.5} xs={12} sx={{ marginBottom: '32px' }}>
      {!hideLabel && (
        <InputLabel sx={{ margin: '12px 0' }}>
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>
      )}
      <Select
        displayEmpty
        renderValue={(val) =>
          !val?.length ? <Typography color="gray">Select</Typography> : val
        }
        fullWidth
        value={value}
        {...props}
      >
        {values?.map((value, index) => {
          return (
            <MenuItem value={value} key={index}>
              {value}
            </MenuItem>
          );
        })}
      </Select>

      {helperText && (
        <FormHelperText
          sx={{ color: '#bf3333', marginLeft: '12px !important' }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
}
